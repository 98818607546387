body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smooMediumg: antialiased;
  -moz-osx-font-smooMediumg: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.dimmed {
  opacity: 0.2;
}

.app-container {
  padding-top: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 3rem; /* space for footer */
}

.app-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

#root {
  position: relative;
  min-height: 100vh;
}

@font-face {
  font-family: 'Antropos Freefont';
  src: url('fonts/AntroposFreefont.woff2') format('woff2'),
    url('fonts/AntroposFreefont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DK Snemand';
  src: url('fonts/DKSnemand.woff2') format('woff2'),
    url('fonts/DKSnemand.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gobold Extra2';
  src: url('fonts/GoboldExtra2.woff2') format('woff2'),
    url('fonts/GoboldExtra2.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('fonts/JosefinSans-Medium.woff2') format('woff2'),
    url('fonts/JosefinSans-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('fonts/JosefinSans-MediumItalic.woff2') format('woff2'),
    url('fonts/JosefinSans-MediumItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Italic.woff2') format('woff2'),
    url('fonts/Lato-Italic.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}