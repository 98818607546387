.costcircle {
  /* align-items is to vertically center the number in the circle */
  display: flex;
  align-items: center;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  margin: auto;
  background: rgb(189, 189, 189);
  font: 48px Arial, sans-serif;
}

.costcircle--fast {
  border: 2px solid rgb(196, 14, 14);
  color: rgb(196, 14, 14);
  
}

.costcircle--slow {
  border: 2px solid rgb(29, 60, 197);
  color: rgb(29, 60, 197);
}